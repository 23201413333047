import React from "react";
import { Card, Row, Col } from "antd";

const { Meta } = Card;

function AnimationPage() {
  const videos = [
    {
      title: "Animasyon 1",
      description: "Ameliyat öncesi nelere dikkat etmeliyim",
      videoPath: "/assets/videos/bolum1.mp4",
    },
    {
      title: "Animasyon 2",
      description: "Ameliyat sonrası hastanede nelere dikkat etmeliyim",
      videoPath: "/assets/videos/bolum2.mp4",
    },
    {
      title: "Animasyon 3",
      description: "Ameliyat sonrası evde nelere dikkat etmeliyim",
      videoPath: "/assets/videos/bolum3.mp4",
    },
  ];

  return (
    <Row gutter={[16, 16]} style={{ marginTop: "1rem", padding: "1rem" }}>
      {videos.map((video, index) => (
        <Col xs={24} md={12} key={index}>
          <Card
            hoverable
            cover={<video src={video.videoPath} controls width="100%" />}
          >
            <Meta title={video.title} description={video.description} />
          </Card>
        </Col>
      ))}
    </Row>
  );
}

export default AnimationPage;
