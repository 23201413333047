import React from "react";
import { Card, Row, Col } from "antd";

const { Meta } = Card;

function EducationPage() {
  const videos = [
    {
      title: "Egzersiz 1",
      videoPath: "/assets/videos/egzersiz1.mp4",
    },
    {
      title: "Egzersiz 2",
      videoPath: "/assets/videos/egzersiz2.mp4",
    },
  ];

  return (
    <Row
      gutter={[16, 16]}
      style={{
        marginTop: "1rem",
        padding: "1rem",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {videos.map((video, index) => (
        <Col xs={24} md={12} key={index}>
          <Card
            hoverable
            cover={<video src={video.videoPath} controls width="100%" />}
          >
            <Meta title={video.title} />
          </Card>
        </Col>
      ))}
    </Row>
  );
}

export default EducationPage;
