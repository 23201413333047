import React, { useState, useEffect } from "react";
import {
  HomeOutlined,
  InfoCircleOutlined,
  YoutubeOutlined,
  CameraOutlined,
  ContactsOutlined,
} from "@ant-design/icons";
import { Layout, Menu, theme } from "antd";
import { Link } from "react-scroll";
import "./styles.css";
const { Header, Content, Sider } = Layout;

const tabs = [
  ["Giriş", "home", "TOTAL DİZ PROTEZİ İLE YAŞAM"],
  ["Öğretim Kitapçığı", "information", "BİLGİLENDİRME KİTAPÇIĞI"],
  ["Animasyon Videoları", "animation", "ANİMASYON VİDEOLARI"],
  ["Egzersiz Videoları", "education", "EGZERSİZ VİDEOLARI"],
  ["İletişim", "about", "İLETİŞİM BİLGİLERİ"],
];

function AppLayout({ children }) {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const [selectedTabKey, setSelectedTabKey] = useState("0");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const items = [
    HomeOutlined,
    InfoCircleOutlined,
    YoutubeOutlined,
    CameraOutlined,
    ContactsOutlined,
  ].map((icon, index) => ({
    key: String(index),
    icon: React.createElement(icon),
    label: (
      <Link
        id={index}
        activeClass="active"
        to={tabs[index][1]}
        spy={true}
        smooth={true}
        offset={-100}
        duration={500}
      >
        {tabs[index][0]}
      </Link>
    ),
  }));

  const globalWindowHeight = window.innerHeight;

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const scrollY = window.scrollY;
    let sectionHeights = {};
    if (!isMobile) {
      sectionHeights = {
        0: windowHeight,
        1: windowHeight * 2,
        2: windowHeight * 3,
        3: windowHeight * 4,
        4: windowHeight * 5,
      };
    } else {
      sectionHeights = {
        0: 0,
        1: parseInt(localStorage.getItem("home")),
        2:
          parseInt(localStorage.getItem("home")) +
          parseInt(localStorage.getItem("information")),
        3:
          parseInt(localStorage.getItem("home")) +
          parseInt(localStorage.getItem("information")) +
          parseInt(localStorage.getItem("animation")),
        4:
          parseInt(localStorage.getItem("home")) +
          parseInt(localStorage.getItem("information")) +
          parseInt(localStorage.getItem("animation")) +
          parseInt(localStorage.getItem("education")),
      };
    }

    Object.entries(sectionHeights).forEach(([section, height]) => {
      if (isMobile) {
        if (scrollY > height) {
          setSelectedTabKey(section);
        }
      } else {
        if (scrollY > height - windowHeight - windowHeight * 0.5) {
          setSelectedTabKey(section);
        }
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  return (
    <Layout
      hasSider
      style={{
        height: `calc(5.5rem + ${globalWindowHeight})`,
      }}
    >
      <Sider
        breakpoint="md" // Add this line
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
        width={"15rem"}
      >
        <div className="demo-logo-vertical" />
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={["0"]}
          selectedKeys={selectedTabKey}
          items={items}
        />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: !isMobile ? "15rem" : "5rem",
        }}
      >
        {
          <Header
            style={{
              display: "flex",
              padding: "0 0 0 0",
              background: colorBgContainer,
              position: "fixed",
              top: "0",
              left: !isMobile ? "15rem" : "5rem",
              width: !isMobile ? "calc(100% - 15rem)" : "calc(100% - 5rem)",
              height: "5.5rem",
              zIndex: "1000",
              margin: "0 0 2rem 0",
              borderBottom: "1.2rem solid #f5f5f5",
              fontWeight: !isMobile && "bold",
              fontSize: !isMobile && "2rem",
              justifyContent: "center",
              minWidth: "20rem",
            }}
          >
            {tabs[selectedTabKey][2]}
          </Header>
        }
        <Content
          style={{
            margin: !isMobile && "1rem 2rem",
            overflow: "initial",
            minWidth: "20rem",
          }}
        >
          <div
            style={{
              padding: "6rem 1rem 1rem 1rem",
              textAlign: "center",
              background: colorBgContainer,
            }}
          >
            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}
export default AppLayout;
