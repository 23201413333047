import React from "react";
import { Typography, Row, Col, Card } from "antd";

const { Title, Paragraph } = Typography;

function HomePage() {
  return (
    <div
      style={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        padding: "1rem",
      }}
    >
      <Row justify={"center"}>
        <Col>
          <img
            style={{
              width: "100%",
              height: "auto",
              objectFit: "cover",
              border: "1px solid #f5f5f5",
              borderRadius: "1%",
            }}
            src="/assets/images/main.png"
            alt="Main"
          />
        </Col>
      </Row>

      <Paragraph>
        Sayın Katılımcılar,
        <br /> Bu web sitesi ‘’ QR Kod Desteki Hasta Öğretiminin Total Diz
        Protezi’ ne bağlı Yaşanan Sorunlar ve Acil Servis Başvuru Oranları’ na
        etkisini inceleyen proje çalışması kapsamında oluşturulmuştur.
      </Paragraph>

      <Title level={4}>
        QR Kod aracılığı ile bağlantı sağlamış olduğunuz bu web sitesinde;
      </Title>

      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={8}>
          <Card title="1. Total Diz Protezi ile İlgili Bilgiler">
            <Paragraph
              style={{
                maxHeight: "8rem",
                overflowX: "auto",
              }}
            >
              Ameliyat öncesi hazırlık, ameliyat günü, ameliyat sonrası klinikte
              (birinci, ikinci ve üçüncü günler) ve taburculuk sonrası evde
              dikkat edilmesi gereken bilgileri içeren hasta öğretim kitapçığına
              ulaşabilirsiniz.
            </Paragraph>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Card title="2. Egzersiz Videoları">
            <Paragraph
              style={{
                maxHeight: "8rem",
                overflowX: "auto",
              }}
            >
              Ameliyat sonrası iyileşme sürecinde size önerilen egzersizleri
              gösteren videolara ulaşabilirsiniz.
            </Paragraph>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Card title="3. Animasyonlar">
            <Paragraph
              style={{
                maxHeight: "8rem",
                overflowX: "auto",
              }}
            >
              Ameliyat öncesi, klinikte ve evde dikkat etmeniz gereken konuları
              içeren animasyonlara ulaşabilirsiniz.
            </Paragraph>
          </Card>
        </Col>
      </Row>

      <Paragraph>
        Size sunulan hasta öğretim kitapçığı, video ve animasyonları istediğiniz
        zaman ve istediğiniz sayıda tekrar edebilirsiniz.
      </Paragraph>

      <Title level={3}>İletişim</Title>
      <Paragraph>
        Web sitesinin iletişim bölümünde araştırmacının adres ve telefon
        numarası bilgileri yer almaktadır. Hastalığınız ve iyileşme
        süreçlerinizle ile ilgili danışmak istediğiniz konularda istediğiniz
        zaman iletişim sağlayabilirsiniz.
      </Paragraph>
    </div>
  );
}

export default HomePage;
