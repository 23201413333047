import React, { useState, useEffect } from "react";
import "./App.css";
import { Element } from "react-scroll";
import HomePage from "./pages/home-page/page";
import InformationPage from "./pages/information-page/page";
import EducationPage from "./pages/education-page/page";
import AnimationPage from "./pages/animation-page/page";
import AboutPage from "./pages/about-page/page";

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const updateSectionHeights = () => {
      const homeHeight = document.getElementById("home")?.clientHeight || 0;
      const informationHeight =
        document.getElementById("information")?.clientHeight || 0;
      const animationHeight =
        document.getElementById("animation")?.clientHeight || 0;
      const educationHeight =
        document.getElementById("education")?.clientHeight || 0;
      const aboutHeight = document.getElementById("about")?.clientHeight || 0;

      localStorage.setItem("home", homeHeight);
      localStorage.setItem("information", informationHeight);
      localStorage.setItem("animation", animationHeight);
      localStorage.setItem("education", educationHeight);
      localStorage.setItem("about", aboutHeight);
    };

    updateSectionHeights();

    window.addEventListener("resize", updateSectionHeights);

    return () => {
      window.removeEventListener("resize", updateSectionHeights);
    };
  }, []);

  return (
    <>
      <Element
        name="home"
        id="home"
        style={{
          display: "flex",
          justifyContent: "center",
          // alignItems: "center",
          height: isMobile ? "100%" : "100vh",
        }}
      >
        <HomePage />
      </Element>
      <Element
        name="information"
        id="information"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          marginTop: isMobile && "8rem",
        }}
      >
        <InformationPage />
      </Element>
      <Element
        name="animation"
        id="animation"
        style={{
          display: "flex",
          justifyContent: "center",
          // alignItems: "center",
          height: isMobile ? "100%" : "100vh",
        }}
      >
        <AnimationPage />
      </Element>
      <Element
        name="education"
        id="education"
        style={{
          display: "flex",
          justifyContent: "center",
          // alignItems: "center",
          height: isMobile ? "100%" : "100vh",
          marginTop: isMobile && "8rem",
        }}
      >
        <EducationPage />
      </Element>
      <Element
        name="about"
        id="about"
        style={{
          display: "flex",
          justifyContent: "center",
          // alignItems: "center",
          height: "100vh",
          marginTop: isMobile && "8rem",
        }}
      >
        <AboutPage />
      </Element>
    </>
  );
}

export default App;
