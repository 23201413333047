import React from "react";
import { useEffect, useState } from "react";
import { Button } from "antd";
function InformationPage() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);
  return (
    <>
      {isMobile ? (
        <div>
          <header
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
            }}
          >
            <h1>Öğretim kitapçığı indirmek için aşağıdaki butona tıklayın</h1>
            <Button type="primary" onClick={handleDownload}>
              İndir
            </Button>
          </header>
        </div>
      ) : (
        <iframe
          title="PDF Viewer"
          src="/assets/files/egitim_kitapcigi.pdf"
          width="100%"
          height="80%"
          type="application/pdf"
        />
      )}
    </>
  );
}
const handleDownload = () => {
  const pdfPath = "/assets/files/egitim_kitapcigi.pdf";
  const link = document.createElement("a");
  link.href = pdfPath;
  link.download = "Eğitim Kitapçığı.pdf";
  link.click();
};

export default InformationPage;
