import React from "react";
import { Descriptions, Row, Col } from "antd";

function AboutPage() {
  return (
    <div
      style={{
        gap: "1rem",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <p style={{ fontSize: "1rem", color: "#666" }}>
        Dr. Öğr. Üyesi Nilgün Özbaş
      </p>

      <Row
        gutter={[16, 16]}
        style={{ width: "100%", justifyContent: "center" }}
      >
        <Col xs={24} sm={24} md={12}>
          <Descriptions bordered column={1}>
            <Descriptions.Item label="Adres">
              Ziraat Mah. Şehit Haluk Çağlar Sokak No: 4 Altındağ, Ankara
            </Descriptions.Item>
            <Descriptions.Item label="Telefon">
              +90 554 837 41 23
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </div>
  );
}

export default AboutPage;
